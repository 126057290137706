import React from "react"

import Heading from "../../components/Heading"

import styles from "./formothersbymothers.module.scss"
import btnStyles from "../../components/button.module.scss"

const Formothersbymothers = () => {

  return(
    <div className={styles.mothersday}>
      <header className={styles.header}>
        <Heading
          tag="h1"
          level="1"
          className={styles.heading1}
        >
          For Mothers, By Mothers
        </Heading>
        <p>At the height of the pandemic, we invited our global community to answer an important question: How can working mothers thrive during quarantine? Your candid, whip-smart suggestions are reflected in the following five propositions.</p>
        <p>Great change starts with small conversations, after all.</p>
      </header>

      <ol className={styles.module}>
        <li>
          <Heading
            tag="h2"
            level="2"
            className={styles.heading}
          >
            1. Consider Focused Meeting Blocks
          </Heading>
          <p className={styles.quote}>“Focus on scheduling meetings in the morning or the afternoon. This way working mothers can prepare for their day by accommodating more time with their children’s schedules.” – Reyna T.</p>
          <p>Mandatory quiet hours (without meetings) would allow mothers to plan ahead and divide their time between work and childcare more efficiently.</p>
        </li>
        <li>
          <Heading
            tag="h2"
            level="2"
            className={styles.heading}
          >
            2. Consider Unlimited or Flex PTO
          </Heading>
          <p className={styles.quote}>“Start a program where flex days, sick days, vacation days or hours can be donated to help working moms.” – Zoby S.</p>
          <p>It’s impossible to predict when a child (or yourself, for that matter) will become unwell. Implementing a more forgiving PTO policy means that mothers aren’t forced to multitask under pressure. Employees should be held accountable by results, not hours worked.</p>
        </li>
        <li>
          <Heading
            tag="h2"
            level="2"
            className={styles.heading}
          >
            3. Consider Stipends for "Life Help"
          </Heading>
          <p className={styles.quote}>“Offer a stipend or similar for moms to arrange in-house childcare, meal delivery services, or admin services.” — Maria H.</p>
          <p>It goes without saying that many working mothers can’t afford in-house help. Updated company perks, such as subsidized lunches or pre-arranged childcare, would be extremely beneficial.</p>
        </li>
        <li>
          <Heading
            tag="h2"
            level="2"
            className={styles.heading}
          >
            4. Consider Reimbursing Home Office Items
          </Heading>
          <p className={styles.quote}>“A purchasing scheme for high-speed broadband and digital tools or devices.” — Susanna F.E.</p>
          <p>Working from home is the new normal. By covering the cost of high-speed wifi, in addition to ergonomic desks and chairs, companies will increase employee productivity and job satisfaction.</p>
        </li>
        <li>
          <Heading
            tag="h2"
            level="2"
            className={styles.heading}
          >
            5. Consider Sponsored Entertainment
          </Heading>
          <p className={styles.quote}>“Program activities for children, like a Zoom cooking class, so parents can worry less about structuring their children’s time outside of school.” — <span style={{"white-space": "nowrap"}}>Jill L.</span></p>
          <p>Providing online educational activities can boost family morale and alleviate the stress of entertaining children during working hours. Even if it is via a screen.</p>
        </li>
      </ol>

      <div className={styles.footer}>
        <Heading
          tag="h3"
          level="3"
          className={styles.heading}
        >
          The Takeaway
        </Heading>
        <p>Want to continue the conversation? We’ve distilled your ideas into a sleek digital flyer that you can download, share and discuss with friends, family, colleagues, and more.</p>
        <p><a href="https://cdn.shopify.com/s/files/1/1103/4464/files/WIP_V4.pdf?v=1588984819" download target="_blank" rel="noopener noreferrer" className={btnStyles.btnDark}>Download Now</a></p>
      </div>
    </div>
  )
}

export default Formothersbymothers
